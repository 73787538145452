import { PulseLoader } from "react-spinners";
import "./index.scss";

export const PageLayout = ({ children, title, descriptions }) => (
  <section className="page">
    <div className="page-title">{title}</div>
    <div className="page-title__description">
      <ul>
        {descriptions?.map((description) => (
          <li>{description}</li>
        ))}
      </ul>
    </div>
    <div className="inner-contents">{children}</div>
  </section>
);

export const FormContainer = ({ children }) => <div className="form-container">{children}</div>;

export const Button = ({ buttonStyle, click, label }) => (
  <button type="button" className={buttonStyle} onClick={click}>
    {label}
  </button>
);

export const Waiting = ({ shouldWait }) => {
  const waitingClass = shouldWait ? "block" : "none";
  return (
    <div className="waiting">
      <div className={waitingClass}>
        <PulseLoader color="#55555599" loading={shouldWait} />
      </div>
    </div>
  );
};
