import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { PageLayout } from "../components";
import "./FinishPage.scss";

class FinishPage extends Component {
  static propTypes = {
    config: PropTypes.object,
  };

  render() {
    const { state } = this.props.location;

    const defaultTitle = "회원정보 확인이 완료되었습니다.";
    const defaultDescription = "회원정보 확인이 완료되었습니다. 정상적으로 계정을 이용하실 수 있습니다.";

    const title = state ? state.title : defaultTitle;
    const descriptions = state ? state.description : defaultDescription;

    return (
      <div className="finish-page">
        <PageLayout title={title}>
          <div className="welcome">
            <p>{descriptions}</p>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default withRouter(FinishPage);
