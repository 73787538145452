import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { PageLayout, Button, FormContainer } from "../../components";
import { startIdentityAuth } from "@riotgames/kr-identity-sdk";

class FraudReportAgreement extends Component {
  constructor(props) {
    super(props);

    props.store.update({ token: props.match.params.token });

    this.state = {
      agreed: false,
      confirms: [
        {
          question: "리그오브레전드 계정의 사용자가 본인이 아닐 경우 검토 대상에 포함되지 않습니다.",
          holder: "",
          answer: "네 알겠습니다",
        },
        {
          question:
            "일명의 모든 계정에서 과거 계정공유(가족, 친구 등), 대리게임 등의 행위가 있을 경우 검토 대상에 포함되지 않습니다.",
          holder: "",
          answer: "네 알겠습니다",
        },
        {
          question:
            "검토는 모든 가능성을 열어두고 진행되지만, 검토 결과가 도용의 가능성이 없는 것으로 판단될 경우 요청 내용의 도움을 드리지 못할 수 있습니다.",
          holder: "",
          answer: "네 알겠습니다",
        },
        {
          question:
            "계정도용 복구는 명의기준 1회에 한하여 도움을 드립니다. (단,1회에 한해서만 도움 받을 수 있으므로 신중히 선택하시기 바랍니다.)",
          holder: "",
          answer: "네 알겠습니다",
        },
        {
          question: "본 서비스를 통해 도움받은 이후에는 항상 계정 보안에 유의해 주시기 바랍니다.",
          holder: "",
          answer: "유의하겠습니다",
        },
      ],
    };
  }

  doSelfAuth() {
    const { config } = this.props;
    const { agreed, confirms } = this.state;

    if (agreed && confirms.filter((item) => item.holder !== item.answer).length === 0) {
      startIdentityAuth({
        serviceType: config.auth.appCode,
        environment: config.auth.env,
        isPopup: true,
        successCallback: this.onAuthSuccess.bind(this),
        failCallback: this.onAuthFailed.bind(this),
      });
    } else {
      alert("동일한 답변이 입력되지 않았거나 동의가 완료되지 않았습니다.");
    }
  }

  onAuthSuccess({ identityToken }) {
    this.props.store.update({ identityToken });
    this.props.history.push("/report");
  }

  onAuthFailed() {
    alert("인증 오류가 발생되었습니다.");
  }

  componentDidMount() {
    const { store, config } = this.props;
    fetch(`${process.env.REACT_APP_FRAUD_API_SERVER}/user/api/v1/report/${this.props.match.params.token}/authorize`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      credentials: "include",
      mode: "cors",
      cache: "no-cache",
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 409) {
            alert(
              "신청 페이지 발급 계정과 다른 계정으로 로그인하셨습니다. 발급된 계정으로 로그인 후 작성부탁드립니다.",
            );
          } else {
            alert("로그인이 필요한 서비스입니다.");
            return config.toLogin(`/fraud/${store.state().token}`);
          }
        }
      })
      .catch(() => {
        alert("서비스와의 접속이 원할하지 않습니다. 잠시 후 다시 시도해주세요.");
        return config.toMain();
      });
  }

  render() {
    const { agreed, confirms } = this.state;

    return (
      <div className="fraud-report-agreement">
        <PageLayout
          title="계정도용 복구검토 신청서"
          descriptions={[
            "신청서를 허위로 작성할 경우 발생하는 책임은 사용자 본인에게 있으니 주의 바랍니다.",
            "다음 문항의 내용을 꼼꼼히 읽어보시고 문항의 답변은 동일하게 작성해야만 다음 화면으로 이동할 수 있습니다.",
          ]}
        >
          <FormContainer title="동일하게 답변을 입력하세요.">
            <ul className="answers">
              {confirms.map((item, index) => (
                <li className="question" key={index}>
                  <div className="label"> {item.question} </div>
                  <div className="input">
                    <input
                      type="text"
                      placeholder={item.answer}
                      value={item.holder}
                      onChange={(e) => {
                        confirms[index] = { ...item, holder: e.target.value };
                        this.setState({ confirms });
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </FormContainer>
          <FormContainer title="계정 보안 팁">
            <ul className="tips">
              <li>다른 사이트와 동일한 계정명, 비밀번호를 사용하지 말아주세요.</li>
              <li>비밀번호는 영어 대문자, 소문자, 특수문자를 포함하여 10글자 이상으로 변경하여 사용하세요.</li>
              <li>주기적으로 비밀번호를 변경하는 것이 좋아요!</li>
              <li>가입된 아이핀이 있을 경우 아이핀 비밀번호를 꼭! 변경해 주세요.</li>
            </ul>
          </FormContainer>

          <div className="confirm">
            <div className="message">위 문항의 답변을 모두 작성하였고, 동의하실 경우 본인인증 페이지로 이동됩니다.</div>
            <label>
              <input type="checkbox" value={agreed} onChange={(e) => this.setState({ agreed: e.target.value })} />
              <span onClick={() => this.setState((state) => ({ agreed: !state.agreed }))}>네, 동의합니다.</span>
            </label>
          </div>

          <div className="buttons">
            <Button label="다음" click={this.doSelfAuth.bind(this)} />
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default withRouter(FraudReportAgreement);
