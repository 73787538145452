import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { PageLayout, FormContainer, Button, Waiting } from "../../components";

import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";

const FraudDate = ({ selected, onChange }) => (
  <DatePicker
    selected={selected}
    dateFormat="yyyy-MM-dd"
    onChange={(selected) => onChange(selected.getTime())}
    locale={ko}
  />
);

class FraudReportRegister extends Component {
  constructor(props) {
    super(props);

    const now = new Date().getTime();

    this.state = {
      // Payload fields
      fraudDatetime: now,
      lastGameDatetime: now,
      fraudStartDatetime: now,
      fraudEndDatetime: now,
      fraudGameCount: 0,
      fraudGameChampion: "",
      lastGameChampion: -1,
      lastPurchaseChampion: -1,
      lastPurchaseSkin: -1,
      lastPurchaseEtc: "",
      mainPC: true,
      comment: "",

      // UI fields
      version: "9.13.1",
      champions: [],
      skins: [],
      shouldWait: false,
    };

    this.saveReport = this.saveReport.bind(this);
  }

  loadChampions() {
    fetch("https://ddragon.leagueoflegends.com/realms/kr.json", { method: "GET" })
      .then((response) => response.json())
      .then((item) => {
        this.setState({ version: item.n.champion });
        return item.n.champion;
      })
      .then((latest) =>
        fetch(`https://ddragon.leagueoflegends.com/cdn/${latest.replace("8608", "")}/data/ko_KR/champion.json`, {
          method: "GET",
        }),
      )
      .then((response) => response.json())
      .then((payload) => payload.data)
      .then((items) => Object.entries(items).map(([ename, item]) => ({ ename: ename, key: item.key, name: item.name })))
      .then((list) => list.sort((a, b) => a.name.localeCompare(b.name)))
      .then((list) =>
        this.setState({
          champions: list.map((item, index) => (
            <option key={index} value={item.name}>
              {item.name}
            </option>
          )),
          skinned: list,
        }),
      );
  }

  loadSkinFor(champion) {
    const { version } = this.state;

    this.state.skinned
      .filter((item) => item.name === champion)
      .map((item) =>
        fetch(`https://ddragon.leagueoflegends.com/cdn/${version}/data/ko_KR/champion/${item.ename}.json`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((champion) =>
            this.setState({
              skins: champion.data[item.ename].skins.map((skin, index) => (
                <option key={index} value={skin.name}>
                  {skin.name}
                </option>
              )),
            }),
          ),
      );
  }

  loadSavedReport() {
    this.setState({ shouldWait: true });
    fetch(`${process.env.REACT_APP_FRAUD_API_SERVER}/user/api/v1/report/${this.props.store.state().token}`, {
      method: "GET",
      headers: {
        Authorization: this.props.store.state().identityToken,
      },
      cache: "no-cache",
      mode: "cors",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          this.saveReport("POST");
          return this.state;
        } else if (response.status === 409) {
          alert("신청 페이지 발급 계정과 다른 계정으로 로그인하셨습니다. 발급된 계정으로 로그인 후 작성부탁드립니다.");
          this.props.config.toMain();
        } else {
          alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
          this.props.config.toMain();
        }
      })
      .then((payload) => {
        this.setState(payload);
        return payload;
      })
      .then(({ lastPurchaseChampion }) => {
        if (lastPurchaseChampion && lastPurchaseChampion !== "") {
          this.loadSkinFor(lastPurchaseChampion);
        }
        this.setState({ shouldWait: false });
      })
      .catch(() => {
        alert("서버와의 연결에 오류가 발생했습니다.");
        this.props.config.toLogin(`/fraud/${this.props.store.state().token}`);
      });
  }

  saveReport(method = "PUT", submit = "") {
    const payload = { ...this.state, version: "9.13.1", champions: [], skins: [], skinned: [] };
    this.setState({ shouldWait: true });
    return fetch(
      `${process.env.REACT_APP_FRAUD_API_SERVER}/user/api/v1/report/${this.props.store.state().token}` + submit,
      {
        method: method,
        headers: {
          Authorization: this.props.store.state().identityToken,
          "Content-Type": "application/json;charset=UTF-8",
        },
        cache: "no-cache",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(payload),
      },
    )
      .then(() => {
        this.setState({ shouldWait: false });
      })
      .catch(() => {
        this.setState({ shouldWait: false });
      });
  }

  submitReport() {
    const now = new Date().getTime();
    const { fraudDatetime, lastGameDatetime, fraudStartDatetime, fraudEndDatetime, fraudGameCount } = this.state;

    if (fraudEndDatetime < fraudStartDatetime) {
      alert("자신이 플레이하지 않은 모든 게임의 기간의 정보가 올바르지 않습니다. 다시 확인해주세요.");
      return;
    }

    if (fraudEndDatetime > now) {
      alert("플레이하지 않은 마지막 날짜 정보가 올바르지 않습니다. 올바른 시간을 입력해주세요.");
      return;
    }

    if (fraudDatetime > now) {
      alert("계정도용 의심 날짜 정보가 올바르지 않습니다.");
      return;
    }
    if (lastGameDatetime > now) {
      alert("자신의 마지막 게임 날짜 정보가 올바르지 않습니다.");
      return;
    }
    if (lastGameDatetime > fraudStartDatetime) {
      alert(
        "자신의 마지막 게임날짜가 플레이하지 않은 날짜와 맞지 않습니다. 정확한 본인의 마지막 게임 날짜를 선택해주세요.",
      );
      return;
    }

    if (fraudGameCount < 0 || fraudGameCount > 999) {
      alert("본인이 플레이하지 않은 게임 수를 0 ~ 9999 사이에서 올바르게 입력해주세요");
      return;
    }

    if (
      window.confirm("제출 이후에는 수정이 불가능하며, 제출 이후에는 1:1 문의를 통해 진행됩니다. 제출하시겠습니까?")
    ) {
      const payload = { ...this.state, version: "9.13.1", champions: [], skins: [], skinned: [] };
      this.setState({ shouldWait: true });
      return fetch(
        `${process.env.REACT_APP_FRAUD_API_SERVER}/user/api/v1/report/${this.props.store.state().token}/submit`,
        {
          method: "PUT",
          headers: {
            Authorization: this.props.store.state().identityToken,
            "Content-Type": "application/json;charset=UTF-8",
          },
          cache: "no-cache",
          mode: "cors",
          credentials: "include",
          body: JSON.stringify(payload),
        },
      )
        .then((response) => {
          if (response.ok) {
            this.props.history.push("/finish", {
              title: "계정도용 복구검토 신청이 완료되었습니다.",
              description: "검토 후 결과는 1:1 문의 답변으로 영업일 기준 최대 14일 이내에 안내될 예정입니다.",
            });
          } else if (response.status === 409) {
            alert(
              "신청 페이지 발급 계정과 다른 계정으로 로그인하셨습니다. 발급된 계정으로 로그인 후 작성부탁드립니다.",
            );
            this.setState({ shouldWait: false });
          } else {
            alert("이미 제출되었거나 인증이 만료되었습니다.");
            this.setState({ shouldWait: false });
          }
        })
        .catch(() => {
          this.setState({ shouldWait: false });
        });
    }
  }

  componentDidMount() {
    this.loadChampions();
    this.loadSavedReport();
  }

  render() {
    const {
      fraudDatetime,
      lastGameDatetime,
      fraudStartDatetime,
      fraudEndDatetime,
      fraudGameCount,
      fraudGameChampion,
      lastGameChampion,
      lastPurchaseChampion,
      lastPurchaseSkin,
      lastPurchaseEtc,
      mainPC,
      comment,
    } = this.state;

    return (
      <div>
        <PageLayout
          title="계정도용 정보 기입"
          descriptions={[
            "계정도용 검토에 참고할 수 있는 아래의 질문에 최대한 자세히 기억나는 대로 적어주세요.",
            "게임 기록은 리그오브레전드 공식 홈페이지 대전기록을 참고해 주세요.",
            "모든 정보를 꼭 다 써야만 하는 것은 아닙니다.",
            "최대한 자세히 정보를 남겨주시면 검토에 큰 도움이 됩니다.",
          ]}
        >
          <FormContainer title="동일하게 답변을 입력하세요.">
            <ul className="answers">
              <li className="question">
                <div className="label">현재 신청서를 작성하는 PC가 주로 사용하는 PC가 맞습니까?</div>
                <div className="input">
                  <input
                    type="radio"
                    name="is-main-pc"
                    checked={mainPC}
                    value={mainPC}
                    onChange={() => this.setState({ mainPC: true })}
                  />
                  예 &nbsp;
                  <input
                    type="radio"
                    name="is-main-pc"
                    checked={!mainPC}
                    value={!mainPC}
                    onChange={() => this.setState({ mainPC: false })}
                  />
                  아니오
                </div>
              </li>

              <li className="question">
                <div className="label"> 계정도용으로 의심되는 날짜를 선택해 주세요. </div>
                <div className="input">
                  <FraudDate
                    selected={fraudDatetime}
                    onChange={(selected) => this.setState({ fraudDatetime: selected })}
                  />
                </div>
              </li>

              <li className="question">
                <div className="label"> 자신이 마지막으로 플레이한 게임 </div>
                <div className="input flex">
                  <div>
                    <span>날짜</span>
                    <FraudDate
                      selected={lastGameDatetime}
                      onChange={(selected) => this.setState({ lastGameDatetime: selected })}
                    />
                  </div>
                  <div>
                    <span>사용 챔피언</span>
                    <select
                      className="champions"
                      value={lastGameChampion}
                      onChange={(e) => this.setState({ lastGameChampion: e.target.value })}
                    >
                      <option value=""> 모름 </option>
                      {this.state.champions}
                    </select>
                  </div>
                </div>
              </li>

              <li className="question">
                <div className="label">
                  마지막으로 본인이 구매한 상품 정보
                  <span className="small">(구매 상품이 스킨일 경우 해당 챔피언 선택 후 스킨을 선택해 주세요.)</span>
                </div>
                <div className="input flex">
                  <div>
                    <span>챔피언</span>
                    <select
                      className="champions"
                      value={lastPurchaseChampion}
                      onChange={(e) => {
                        this.setState({ lastPurchaseChampion: e.target.value, lastPurchaseSkin: -1 });
                        this.loadSkinFor(e.target.value);
                      }}
                    >
                      <option value=""> 모름 </option>
                      {this.state.champions}
                    </select>
                  </div>
                  <div>
                    <span>스킨</span>
                    <select
                      className="skins"
                      value={lastPurchaseSkin}
                      onChange={(e) => this.setState({ lastPurchaseSkin: e.target.value })}
                    >
                      <option value=""> 모름 </option>
                      {this.state.skins}
                    </select>
                  </div>
                  <div>
                    <span>기타</span>
                    <input
                      type="text"
                      className="line"
                      placeholder="스킨,룬,부스트,기타"
                      value={lastPurchaseEtc}
                      onChange={(e) => this.setState({ lastPurchaseEtc: e.target.value })}
                    />
                  </div>
                </div>
              </li>
              <li className="question">
                <div className="label">자신이 플레이하지 않은 모든 게임</div>
                <div className="input">
                  <ul className="table">
                    <li>
                      <div className="column">기간</div>
                      <span>
                        <FraudDate
                          selected={fraudStartDatetime}
                          onChange={(selected) => this.setState({ fraudStartDatetime: selected })}
                        />
                        <span> ~ </span>
                        <FraudDate
                          selected={fraudEndDatetime}
                          onChange={(selected) => this.setState({ fraudEndDatetime: selected })}
                        />
                      </span>
                    </li>
                    <li>
                      <div className="column"> 게임 횟수 </div>
                      <span>
                        <input
                          type="number"
                          className="numbers"
                          value={fraudGameCount}
                          onChange={(e) => this.setState({ fraudGameCount: e.target.value })}
                          min="0"
                          max="9999"
                          maxLength="4"
                        />
                      </span>
                    </li>
                    <li>
                      <div className="column"> 사용 챔피언 </div>
                      <span>
                        <input
                          type="text"
                          value={fraudGameChampion}
                          onChange={(e) => this.setState({ fraudGameChampion: e.target.value })}
                          className="line"
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="question">
                <div className="label">
                  이외에 추가적으로 전달해 주실 정보가 있다면 간략하게 서술해 주세요. (1000자 이내)
                </div>
                <div className="input">
                  <textarea
                    className="appendix"
                    maxLength="1000"
                    value={comment}
                    onChange={(e) => this.setState({ comment: e.target.value })}
                  />
                </div>
              </li>
            </ul>
            <div className="buttons">
              <Button label="임시저장" buttonStyle="gray" click={() => this.saveReport()} />
              <Button label="제출" click={this.submitReport.bind(this)} />
            </div>
          </FormContainer>
        </PageLayout>
        <Waiting shouldWait={this.state.shouldWait} />
      </div>
    );
  }
}

export default withRouter(FraudReportRegister);
